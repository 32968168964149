/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as Icon from 'react-feather';
import IconButton from '@mui/material/IconButton';
import { toast, Flip } from 'react-toastify';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function PdfViewer({ modal, toggle, data, handleSendAlert }) {
  const [file, setFile] = useState('');
  const [item, setItem] = useState();
  const [itemIndex, setItemIndex] = useState(-1);
  const [addDisable, setAddDisable] = useState(false);
  const [subDisable, setSubDisable] = useState(true);

  const getFile = (name) => {
    fetch(`${process.env.REACT_APP_BASE_URL}/accounting/files/${name}`, {
      method: 'GET',
      headers: {
        Authorization:
          'Bearer ' +
          localStorage.getItem(
            localStorage.getItem('current_account') + '_access_token'
          ),
      },
    })
      .then((response) => {
        response.blob().then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          setFile(fileURL);
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const add = () => {
    if (itemIndex < data.length - 1) {
      setItem(data[itemIndex + 1]);
      setItemIndex(itemIndex + 1);
      getFile(data[itemIndex + 1].fileName);
      setAddDisable(false);
      setSubDisable(false);
    }
    if (itemIndex + 1 === data.length - 1) {
      setAddDisable(true);
    }
  };

  const sub = () => {
    if (itemIndex > 0) {
      setItem(data[itemIndex - 1]);
      setItemIndex(itemIndex - 1);
      getFile(data[itemIndex - 1].fileName);
      setSubDisable(false);
      setAddDisable(false);
    }
    if (itemIndex - 1 === 0) {
      setSubDisable(true);
    }
  };

  useEffect(() => {
    console.log(data);
    setAddDisable(false);
    setSubDisable(true);

    if (data.length === 1) {
      setAddDisable(true);
      setSubDisable(true);
    }
    if (data[0]) {
      setItemIndex(0);
      setItem(data[0]);
      getFile(data[0].fileName);
    }
  }, [data]);

  return (
    <div>
      <ModalBody
        style={{
          minHeight: '80vh',
          height: '80vh',
        }}
      >
        <div style={{ height: '100%' }}>
          <iframe
            src={file}
            type="application/pdf"
            width="100%"
            height="100%"
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {item ? <h4>Received: {item.email}</h4> : null}
        <IconButton
          disabled={subDisable}
          onClick={() => {
            sub();
          }}
          color="primary"
          component="label"
        >
          <ArrowBackIosNewIcon />
        </IconButton>
        {data ? (
          <h3> {'(' + (itemIndex + 1) + '/' + data.length + ')'}</h3>
        ) : null}
        <IconButton
          disabled={addDisable}
          onClick={() => {
            add();
          }}
          color="primary"
          component="label"
        >
          <ArrowForwardIosIcon />
        </IconButton>

        <Button
          size="sm"
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            handleSendAlert();
          }}
        >
          <Icon.Send size={22} /> Send
        </Button>
        <Button
          size="sm"
          color="success"
          className="d-flex align-items-center"
          type="button"
          onClick={() => {
            toggle();
          }}
        >
          <Icon.Power size={22} /> Cancel
        </Button>
      </ModalFooter>
    </div>
  );
}
