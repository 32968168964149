/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import Moment from 'react-moment';
import {
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Col,
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import {
  colors,
  Box,
  CardMedia,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Stack,
  Autocomplete,
  Paper,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import enLocale from 'date-fns/locale/en-US';
import { styled } from '@mui/material/styles';
import * as Icon from 'react-feather';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast, Flip } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import Cropper from 'react-cropper';
import SweetAlert from '../../../../../components/main/SweetAlert';
import unitService from '../../../../../services/units';
import fileUploadService from '../../../../../services/file_upload';
import stateProvinceService from '../../../../../services/state_province';
import driverService from '../../../../../services/drivers';
import statementService from '../../../../../services/statements';
import UploadIcon from '../../../../../assets/icons/upload.svg';
import ModalWindow from '../../../../../components/main/Modals';
import ModalWindowSimple from '../../../../../components/main/Modals/Simple';
import MobileAppAccess from '../MobileAppAccess';
import Loading from '../../../../../components/main/Loading';
import DragAndDrop from '../../components/DragAndDrop';
import AttachmentItem from '../../components/AttachmentItem';
import AdditionForm from './AdditionForm';
import DeductionForm from './DeductionForm';
import { nationalities } from '../../../../../redux/constants/nationalities';
import { useStyles } from '../../../../../style/style';

const columns = [
  { id: 'id', label: '', maxWidth: 0 },
  { id: 'number', label: '№', minWidth: 40, align: 'left' },
  {
    id: 'date',
    label: 'DATE',
    minWidth: 70,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'amount',
    label: 'AMOUNT',
    minWidth: 70,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'note',
    label: 'NOTE',
    minWidth: 70,
    align: 'left',
    format: (value) => value.toFixed(2),
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4d4d4d',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function NewDriver({ toggle, id }) {
  const classes = useStyles();
  const [states, setStates] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [licenseFileId, setLicenseFileId] = useState(null);
  const [medicalCardFileId, setMedicalCardFileId] = useState(null);
  const [trucks, setTrucks] = useState([]);
  const [driverStatuses, setDriverStatuses] = useState([]);

  const [driverId, setDriverId] = useState(id);

  const [socialSecurityFileId, setSocialSecurityFileId] = useState(null);
  const [driverPermissions, setDriverPermissions] = useState([
    'Active',
    'Terminate',
    'In process',
  ]);

  const [selfie, setSelfie] = useState();
  const [photo, setPhoto] = useState('');
  const [cropper, setCropper] = useState();
  const [openCrop, setOpenCrop] = useState(false);
  const [image, setImage] = useState('');

  const [openAdditionModal, setOpenAdditionModal] = useState(false);
  const [openDeductionModal, setOpenDeductionModal] = useState(false);

  const [pageAddition, setPageAddition] = useState(0);
  const [rowsPerPageAddition, setRowsPerPageAddition] = useState(10);

  const [pageDeduction, setPageDeduction] = useState(0);
  const [rowsPerPageDeduction, setRowsPerPageDeduction] = useState(10);

  const [additions, setAdditions] = useState([]);
  const [additionId, setAdditionId] = useState();

  const [deductions, setDeductions] = useState([]);
  const [deductionId, setDeductionId] = useState();

  const [replaceImageStatus, setReplaceImageStatus] = useState(false);
  const [imageFileId, setImageFileId] = useState();

  const [loading, setLoading] = useState(true);
  const [showMobileAppAccessModal, setShowMobileAppAccessModal] =
    useState(false);
  const [login, setLogin] = useState('');

  const [openDrop, setOpenDrop] = useState(false);

  const [filesUpload, setFilesUpload] = useState([]);
  const [fileId, setFileId] = useState();

  const [deleteCDLFile, setDeleteCDLFile] = useState(false);

  const token = localStorage.getItem(
    localStorage.getItem('current_account') + '_access_token'
  );
  const decodedToken = jwtDecode(token);
  const username = decodedToken.preferred_username.split('_')[2];

  const handleChangePageAddition = (event, newPage) => {
    setPageAddition(newPage);
  };

  const handleChangeRowsPerPageAddition = (event) => {
    setPageAddition(0);
    setRowsPerPageAddition(+event.target.value);
  };

  const handleChangePageDeduction = (event, newPage) => {
    setPageDeduction(newPage);
  };

  const handleChangeRowsPerPageDeduction = (event) => {
    setPageDeduction(0);
    setRowsPerPageDeduction(+event.target.value);
  };

  const newDriver = (photoFileId, values) => {
    const data = {
      photoFileId,
      socialSecurityFileId,
      alternatePhone: values.alternatePhone,
      city: values.city,
      defaultPaymentTypeId: parseInt(values.defaultPaymentTypeId, 10),
      paymentValue: values.paymentValue,
      depositCollected: values.depositCollected,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      payTo: values.payTo,
      birthDate: new Date(document.querySelector('#birthDate').value).getTime(),
      nationality: values.nationality,
      licenseExpiration: document.querySelector('#licenseExpiration').value,
      licenseIssuedJurisdictionId: values.licenseIssuedJurisdictionId,
      licenseNumber: values.licenseNumber,
      licenseIssueTime: new Date(
        document.querySelector('#licenseIssueTime').value
      ).getTime(),
      medicalCardRenewal: document.querySelector('#medicalCardRenewal').value,
      hireDate: document.querySelector('#hireDate').value,
      phone: values.phone,
      stateProvinceId: parseInt(values.stateProvinceId, 10),
      street: values.street,
      terminationDate:
        document.querySelector('#terminationDate').value === ''
          ? null
          : document.querySelector('#terminationDate').value,
      zipCode: values.zipCode,
      licenseFileId,
      medicalCardFileId,
      note: values.note,
      truckId: parseInt(values.truckId, 10),
      driverStatusId: parseInt(values.driverStatusId, 10),
    };
    if (values.permission === 'In process') {
      data.inProcess = true;
    } else if (values.permission === 'Active') {
      data.deleted = false;
      data.inProcess = false;
    } else if (values.permission === 'Terminate') {
      data.deleted = true;
      data.inProcess = false;
    }
    if (id) {
      data.id = id;
      driverService
        .update(data)
        .then((res) => {
          if (res) {
            toast.success('Driver successfuly edited', {
              transition: Flip,
            });

            // toggle();
          } else {
            toast.error('Something went wrong', { transition: Flip });
            // res.text();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      driverService
        .create(data)
        .then((res) => {
          if (res) {
            toast.success('Driver successfuly added', {
              transition: Flip,
            });
            driverService
              .getOne(res.id)
              .then((data) => {
                setDriverId(res.id);
              })
              .catch((err) => {
                toast.error('Something went wrong', {
                  transition: Flip,
                });
              });
            // toggle();
          } else {
            toast.error('Something went wrong', { transition: Flip });
            // res.text();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    }
  };

  const uploadSelfie = (values) => {
    console.log(photo);
    if (photo !== '') {
      const formData = new FormData();
      formData.append('file', photo, new Date().getTime());

      fileUploadService
        .upload('/file/upload', formData)
        .then((data) => {
          console.log(data);
          newDriver(data, values);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            transition: Flip,
          });
        });
    } else {
      newDriver(null, values);
    }
  };

  const uploadLicense = (file) => {
    const formData = new FormData();
    formData.append('file', file, 'license');

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setLicenseFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadSSN = (file) => {
    const formData = new FormData();
    formData.append('file', file, file.name);

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setSocialSecurityFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const uploadMedicalCart = (file) => {
    const formData = new FormData();
    formData.append('file', file, 'cart');

    fileUploadService
      .upload('/file/upload', formData)
      .then((data) => setMedicalCardFileId(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const componentDidMount = () => {
    stateProvinceService
      .getAll()
      .then((data) => setStates(data))
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    driverService
      .getContext()
      .then((data) => {
        const statuses = data.driver_statuses;
        const removeValFromIndex = [0, 1, 3];
        removeValFromIndex.forEach((item, i) => {
          statuses.splice(removeValFromIndex[i], 1);
        });

        setPaymentTypes(data.payment_types);
        setDriverStatuses(statuses);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
    unitService
      .getAll('size=10000')
      .then((data) => {
        setTrucks(data.content);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const deleteSSN = () => {
    setSocialSecurityFileId(null);
  };

  const deleteMedicalCard = () => {
    setMedicalCardFileId(null);
  };

  const toggleMobileAppAccess = () => {
    setShowMobileAppAccessModal(!showMobileAppAccessModal);
  };
  const imgSelectHandler = (e) => {
    if (e.target.files.length !== 0) {
      setImage(URL.createObjectURL(e.target.files[0]));
      // setPreviewImg(URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
      setOpenCrop(!openCrop);
    }
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setSelfie(cropper.getCroppedCanvas().toDataURL());
      cropper.getCroppedCanvas().toBlob((blob) => {
        setPhoto(
          new File([blob], 'image.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          })
        );
      });
    }
  };

  const handleCropModal = () => {
    setOpenCrop(!openCrop);
  };

  const handleDrop = (filesData) => {
    setOpenDrop(!openDrop);

    const fileListUpload = filesUpload;
    Array.from(filesData).forEach((data) => {
      const a = fileListUpload.length + 1;
      const filterList = fileListUpload.filter((element) => {
        return element.name === data.name;
      });

      if (filterList.length > 0) {
        const spName = data.name.split('.');
        let name = '';
        spName.forEach((val, i) => {
          if (i < spName.length - 1) {
            name += val + '-';
          }
        });
        fileListUpload.push({
          id: a,
          name:
            name +
            'Copy(' +
            filterList.length +
            ').' +
            data.name.split('.').pop(),
          size: data.size,
          file: data,
        });
      } else {
        fileListUpload.push({
          id: a,
          name: data.name,
          size: data.size,
          file: data,
        });
      }
    });

    setFilesUpload(fileListUpload);
  };

  const removeCDLFile = () => {
    driverService
      .deleteLicenseFile(id, fileId)
      .then(() => {
        setDeleteCDLFile(false);
        componentDidMount();
      })
      .catch((err) => {
        setDeleteCDLFile(false);
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const handlerLicense = (id) => {
    setFileId(id);
    setDeleteCDLFile(true);
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('required'),
    lastName: Yup.string().required('required'),
    payTo: Yup.string().required('required'),
    licenseNumber: Yup.string().required('required'),
  });

  const formik = useFormik({
    initialValues: {
      permission: 'Active',
      alternatePhone: '',
      city: '',
      defaultPaymentTypeId: '',
      paymentValue: '',
      depositCollected: '',
      email: '',
      firstName: '',
      payTo: '',
      hireDate: '',
      lastName: '',
      middleName: '',
      birthDate: new Date().getTime(),
      nationality: 'Afghan',
      licenseExpiration: '',
      licenseIssuedJurisdictionName: 'Alabama',
      licenseIssuedJurisdictionId: '1',
      licenseNumber: '',
      licenseIssueTime: new Date().getTime(),
      medicalCardRenewal: '',
      phone: '',
      stateProvinceName: 'Alabama',
      stateProvinceId: '1',
      street: '',
      terminationDate: '',
      zipCode: '',
      note: '',
      truckName: '',
      truckId: '',
      driverStatusId: '',
    },
    validationSchema,
    onSubmit: (values) => {
      uploadSelfie(values);
    },
  });

  const dateFormat = (dateValue) => {
    const date = new Date(dateValue + 1 * 24 * 60 * 60 * 1000);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const dateS = day.toString().padStart(2, '0');

    const monthS = month.toString().padStart(2, '0');

    return `${year}-${monthS}-${dateS}`;
  };

  const getStatementAdditions = () => {
    const query = `size=${rowsPerPageAddition}&driver_id=${id}&deleted=false`;
    statementService
      .getAllAdditions(query)
      .then((data) => {
        console.log(data);
        setAdditions(data.content);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getStatementDeductions = () => {
    const query = `size=${rowsPerPageDeduction}&driver_id=${id}&deleted=false`;
    statementService
      .getAllDeductions(query)
      .then((data) => {
        console.log(data);
        setDeductions(data.content);
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          transition: Flip,
        });
      });
  };

  const getOneDriver = () => {
    driverService.getOne(id).then((data) => {
      setLicenseFileId(data.licenseFileId);
      setMedicalCardFileId(data.medicalCardFileId);
      // setStatus(data.driverStatusId);
      setSocialSecurityFileId(data.socialSecurityFileId);
      setLoading(false);
      setLogin(data.login);

      formik.setValues({
        ...formik.values,
        firstName: data.firstName,
        lastName: data.lastName,
        middleName: data.middleName,
        payTo: data.payTo,
        birthDate: dateFormat(data.birthDate),
        nationality: data.nationality,
        truckId: data.truckId,
        truckName: data.truckNumber,
        driverStatusId: data.driverStatusId,
        street: data.street,
        stateProvinceId: data.stateProvinceId,
        stateProvinceName: data.stateProvinceName,
        city: data.city,
        zipCode: data.zipCode,
        note: data.note,
        email: data.email,
        phone: data.phone,
        defaultPaymentTypeId: data.defaultPaymentTypeId,
        paymentValue: data.paymentValue,
        depositCollected: data.depositCollected,
        licenseNumber: data.licenseNumber,
        licenseIssuedJurisdictionId: data.licenseIssuedJurisdictionId,
        licenseIssuedJurisdictionName: data.licenseIssuedJurisdictionName,
        terminationDate: data.terminationDate,
      });

      if (data.inProcess) {
        formik.setFieldValue('permission', 'In process');
        // document.querySelector('#permission').value = 'In process';
      } else if (!data.deleted) {
        formik.setFieldValue('permission', 'Active');
        // document.querySelector('#permission').value = 'Active';
      } else {
        formik.setFieldValue('permission', 'Terminate');
        // document.querySelector('#permission').value = 'Terminate';
      }

      if (data.photoFileId !== null) {
        if (data.photoFileId !== undefined) {
          fileUploadService
            .download('/file/image/' + data.photoFileId)
            .then((image) => {
              setSelfie('data:image/png;base64,' + image);
              // setImageFileId(data.photoFileId);
            })
            .catch((err) => {
              toast.error(err.response.data.message, {
                transition: Flip,
              });
            });
        }
      }

      if (data.birthDate !== null) {
        document.querySelector('#birthDate').value = dateFormat(data.birthDate);
      }
      if (data.licenseIssueTime !== null) {
        document.querySelector('#licenseIssueTime').value = dateFormat(
          data.licenseIssueTime
        );
      }

      if (data.hireDate !== null) {
        document.querySelector('#hireDate').value = data.hireDateFormatted;
      }

      if (data.terminationDate !== null) {
        document.querySelector('#terminationDate').value =
          data.terminationDateFormatted;
      }

      if (data.licenseExpiration !== null) {
        document.querySelector('#licenseExpiration').value =
          data.licenseExpirationFormatted;
      }

      if (data.medicalCardRenewal !== null) {
        document.querySelector('#medicalCardRenewal').value =
          data.medicalCardRenewalFormatted;
      }

      if (data.licenseFileIds !== null) {
        // const arrFile = [];
        // data.licenseFileIds.forEach((item, i) => {
        //   arrFile.push(item.id);
        // });
        // setFileIds(arrFile);
        setFilesUpload(data.licenseFileIds);
      }
    });
  };

  useEffect(() => {
    componentDidMount();
    if (id) {
      getOneDriver();
      getStatementAdditions();
      getStatementDeductions();
    }
  }, []);

  const renderButton = (state, isSubmitting) => {
    if (state) {
      if (isSubmitting) {
        return 'Updating';
      }
      return 'Update';
    }
    if (isSubmitting) {
      return 'Submitting';
    }
    return 'Save driver';
  };

  return (
    <>
      {/* <Loading loading={loading} /> */}
      <SweetAlert
        title="Are you sure?"
        warning
        show={deleteCDLFile}
        showCancel
        reverseButtons
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        onConfirm={() => {
          removeCDLFile();
        }}
        onCancel={() => setDeleteCDLFile(false)}
      >
        {}
      </SweetAlert>
      <ModalWindowSimple
        title="Addition modal"
        modal={openAdditionModal}
        handleModal={() => {
          setOpenAdditionModal(!openAdditionModal);
        }}
        maxWidth="sm"
        body={
          <AdditionForm
            toggle={() => {
              setOpenAdditionModal(false);
              getStatementAdditions();
            }}
            additionId={additionId}
            driverId={id}
            truckId={null}
          />
        }
      />
      <ModalWindowSimple
        title="Deduction modal"
        modal={openDeductionModal}
        handleModal={() => {
          setOpenDeductionModal(!openDeductionModal);
        }}
        maxWidth="sm"
        body={
          <DeductionForm
            toggle={() => {
              setOpenDeductionModal(false);
              getStatementDeductions();
            }}
            deductionId={deductionId}
            driverId={id}
            truckId={null}
          />
        }
      />

      <MobileAppAccess
        driverId={id}
        login={login}
        modal={showMobileAppAccessModal}
        toggle={toggleMobileAppAccess}
      />
      <ModalWindow
        title="Image cropper"
        // modal={openCrop}
        handleModal={handleCropModal}
        maxWidth="md"
        body={
          <Box>
            <div style={{ width: '100%' }}>
              <Cropper
                style={{ height: 400, width: '100%' }}
                zoomTo={0}
                initialAspectRatio={1}
                preview=".img-preview"
                src={image}
                viewMode={1}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  setCropper(instance);
                }}
                guides={true}
              />
            </div>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  textTransform: 'none',
                }}
                variant="contained"
                onClick={() => handleCropModal()}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: '#2A455F',
                  color: 'white',
                  textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                // startIcon={
                //   isSubmitting ? <CircularProgress size="0.9rem" /> : undefined
                // }
                onClick={() => {
                  getCropData();
                  handleCropModal();
                }}
              >
                Ready
              </Button>
            </Box>
          </Box>
        }
      />
      <Card>
        <CardHeader>
          <h3 className="mb-0">Adding a new Driver</h3>
        </CardHeader>
        <CardBody>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Grid
              container
              direction="row"
              // justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} md={driverId ? 4 : 6} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Active/Terminate/In process
                    </Typography>
                    <FormControl
                      size="small"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <Select
                        label=""
                        // className={classes.input}
                        fullWidth
                        name="permission"
                        value={formik.values.permission}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          console.log(e);
                          formik.setFieldValue('permission', e.target.value);
                        }}
                      >
                        {driverPermissions.map((item, index) => (
                          <MenuItem key={`${index + 1}`} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <Typography align="left" color={colors.grey[700]}>
                      Photo
                    </Typography>
                    <Box
                      sx={{
                        py: 1,
                      }}
                    >
                      <CardMedia
                        component="img"
                        style={{
                          width: 210,
                          height: 210,
                          borderRadius: 5,
                          objectFit: 'fill',
                        }}
                        image={selfie || UploadIcon}
                        alt=""
                      />
                      <input
                        id="selectFile"
                        type="file"
                        accept=".png,.jpg"
                        style={{ display: 'none' }}
                        onChange={imgSelectHandler.bind(this)}
                      />
                      <Box
                        sx={{
                          py: 0.5,
                        }}
                      />
                      <Button
                        // type="file"
                        fullWidth
                        size="small"
                        variant="contained"
                        sx={{
                          width: 210,
                          textTransform: 'none',
                          fontSize: 20,
                          transition: 'all 0.3s ease-in-out',
                          backgroundColor: '#2A455F',
                          '&:hover': {
                            backgroundColor: '#2A455F',
                            opacity: 0.8,
                          },
                        }}
                        color="error"
                        onClick={() => {
                          document.getElementById('selectFile').click();
                        }}
                      >
                        Select
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>First name</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      // className={classes.search}
                      name="firstName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.firstName}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.firstName &&
                        formik.touched.firstName &&
                        formik.errors.firstName}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Middle name
                    </Typography>
                    <TextField
                      // className={classes.search}
                      name="middleName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.middleName}
                      error={
                        formik.touched.middleName &&
                        Boolean(formik.errors.middleName)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>Last name</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      // className={classes.search}
                      name="lastName"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.lastName}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.lastName &&
                        formik.touched.lastName &&
                        formik.errors.lastName}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>Pay to</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      // className={classes.search}
                      name="payTo"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.payTo}
                      error={
                        formik.touched.payTo && Boolean(formik.errors.payTo)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.payTo &&
                        formik.touched.payTo &&
                        formik.errors.payTo}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>Birth date</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Flatpickr id="birthDate" className="form-control" />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>Nationality</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="combo-box11"
                      freeSolo
                      clearOnBlur
                      className={classes.search}
                      value={formik.values.nationality}
                      options={nationalities}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue('nationality', newValue);
                        }
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option}</li>
                      )}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Status
                    </Typography>
                    <FormControl
                      size="small"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      {/* <InputLabel id="statuses">
                        <span>Status</span>
                      </InputLabel> */}
                      <Select
                        // labelId="statuses"
                        // label="Status"
                        // className={classes.input}
                        fullWidth
                        name="driverStatusId"
                        value={formik.values.driverStatusId}
                        onBlur={formik.handleBlur}
                        onChange={(evt) => {
                          formik.setFieldValue(
                            'driverStatusId',
                            evt.target.value
                          );
                        }}
                      >
                        {driverStatuses.map((item, index) => (
                          <MenuItem key={`${index + 1}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Truck
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="combo-box12"
                      freeSolo
                      clearOnBlur
                      className={classes.search}
                      value={formik.values.truckName}
                      options={trucks}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue('truckName', newValue.number);
                          formik.setFieldValue('truckId', newValue.id);
                        }
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option.number;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.number}</li>
                      )}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Street
                    </Typography>
                    <TextField
                      // label="Street"
                      // className={classes.search}
                      name="street"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.street}
                      error={
                        formik.touched.street && Boolean(formik.errors.street)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>City</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <TextField
                      // label="City"
                      // className={classes.search}
                      name="city"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.city}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>State/Province</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="combo-box13"
                      freeSolo
                      clearOnBlur
                      className={classes.search}
                      value={formik.values.stateProvinceName}
                      options={states}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue(
                            'stateProvinceName',
                            newValue.name
                          );
                          formik.setFieldValue('stateProvinceId', newValue.id);
                        }
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Zip code
                    </Typography>
                    <TextField
                      // label="Zip Code"
                      // className={classes.search}
                      name="zipCode"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.zipCode}
                      error={
                        formik.touched.zipCode && Boolean(formik.errors.zipCode)
                      }
                    />
                  </Grid>
                  <Grid item xl={12} md={12} xs={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Note
                    </Typography>
                    <TextField
                      // label="Note"
                      size="small"
                      InputLabelProps={{
                        style: { color: 'black' },
                      }}
                      name="note"
                      fullWidth
                      multiline
                      rows={4}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.note}
                      // error={formik.touched.notes && Boolean(formik.errors.notes)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={driverId ? 4 : 6} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Email
                    </Typography>
                    <TextField
                      // label="Email"
                      // className={classes.search}
                      name="email"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.email}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                    />
                    {/* <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.email &&
                        formik.touched.email &&
                        formik.errors.email}
                    </FormHelperText> */}
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Phone
                    </Typography>
                    <TextField
                      // label="Phone"
                      // className={classes.search}
                      name="phone"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.phone}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      <span>Default payment type</span>
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <FormControl
                      size="small"
                      sx={{ width: '100%', backgroundColor: 'white' }}
                    >
                      <Select
                        // labelId="paymentTypes"
                        // label="Default Payment Type"
                        // className={classes.input}
                        fullWidth
                        name="defaultPaymentTypeId"
                        value={formik.values.defaultPaymentTypeId}
                        onBlur={formik.handleBlur}
                        onChange={(event) => {
                          console.log(event);
                          formik.setFieldValue(
                            'defaultPaymentTypeId',
                            event.target.value
                          );
                        }}
                      >
                        {paymentTypes.map((item, index) => (
                          <MenuItem key={`${index + 1}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Payment value
                    </Typography>
                    <TextField
                      // label="Payment value"
                      // className={classes.search}
                      name="paymentValue"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.paymentValue}
                      error={
                        formik.touched.paymentValue &&
                        Boolean(formik.errors.paymentValue)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.paymentValue &&
                        formik.touched.paymentValue &&
                        formik.errors.paymentValue}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Deposit collected
                    </Typography>
                    <TextField
                      // label="Payment value"
                      // className={classes.search}
                      name="depositCollected"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      value={formik.values.depositCollected}
                      error={
                        formik.touched.depositCollected &&
                        Boolean(formik.errors.depositCollected)
                      }
                    />
                    <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.depositCollected &&
                        formik.touched.depositCollected &&
                        formik.errors.depositCollected}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      License number
                    </Typography>
                    <TextField
                      // label="License number"
                      // className={classes.search}
                      name="licenseNumber"
                      autoComplete="namer"
                      type="text"
                      fullWidth
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      variant="outlined"
                      // placeholder={t('enter_full_name')}
                      value={formik.values.licenseNumber}
                      error={
                        formik.touched.licenseNumber &&
                        Boolean(formik.errors.licenseNumber)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <InputLabel id="licenseExpirationLabel">
                      <span>License Expiration</span>
                    </InputLabel>
                    <Flatpickr
                      id="licenseExpiration"
                      className="form-control"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <InputLabel id="licenseIssueTimeLabel">
                      <span>CDL issue date</span>
                    </InputLabel>
                    <Flatpickr id="licenseIssueTime" className="form-control" />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      License issuing state/jurisdiction
                    </Typography>
                    <Autocomplete
                      fullWidth
                      size="small"
                      id="combo-box14"
                      freeSolo
                      clearOnBlur
                      className={classes.search}
                      value={formik.values.licenseIssuedJurisdictionName}
                      options={states}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          formik.setFieldValue(
                            'licenseIssuedJurisdictionName',
                            newValue.name
                          );
                          formik.setFieldValue(
                            'licenseIssuedJurisdictionId',
                            newValue.id
                          );
                        }
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }

                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // label="License issuing state/jurisdiction"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <InputLabel id="medicalCardRenewalLabel">
                      <span>Medical Card Renewal</span>
                    </InputLabel>
                    <Flatpickr
                      id="medicalCardRenewal"
                      className="form-control"
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <InputLabel id="hireDateLabel">
                      <span>Hire Date</span>
                    </InputLabel>
                    <Flatpickr id="hireDate" className="form-control" />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <InputLabel id="terminationDateLabel">
                      <span>Termination Date</span>
                    </InputLabel>
                    <Flatpickr id="terminationDate" className="form-control" />
                  </Grid>
                  {/* <FormGroup className="align-items-center" row>
                <Col md="4">
                  <span>Social security number</span>
                </Col>
                <Col md="8">
                  <Input type="text" id="ssn" placeholder="" />
                </Col>
              </FormGroup> */}
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      License
                    </Typography>
                    <CustomInput
                      type="file"
                      id="licenseFile"
                      onInput={(e) => uploadLicense(e.target.files[0])}
                    />
                  </Grid> */}
                  {/* <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      Medical Cart
                    </Typography>

                    <CustomInput
                      type="file"
                      id="medicalCart"
                      onInput={(e) => uploadMedicalCart(e.target.files[0])}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} sm={12}>
                    <Typography align="left" color={colors.grey[700]}>
                      SSN
                    </Typography>

                    <CustomInput
                      type="file"
                      id="ssn"
                      onInput={(e) => uploadSSN(e.target.files[0])}
                    />
                  </Grid> */}
                  <Grid item xs={12} md={12} sm={12} my={1}>
                    <Typography
                      variant="h6"
                      align="left"
                      color={colors.grey[700]}
                    >
                      CDL (old, new if available){' '}
                      <span style={{ color: 'red' }}> *</span>
                    </Typography>
                    <input
                      id="licenseFiles"
                      style={{ display: 'none' }}
                      type="file"
                      multiple
                      hidden
                      onChange={(e) => {
                        handleDrop(e.target.files);
                      }}
                      name="[licenseFiles]"
                    />

                    <DragAndDrop handlerDrop={handleDrop}>
                      <div
                        style={{
                          minHeight: '250px',
                          maxHeight: '250px',
                          // display: 'flex',
                          // gap: '6rem',
                          // minWidth: '400px',
                        }}
                      >
                        {filesUpload.map((file, i) => {
                          return (
                            <AttachmentItem
                              key={`${i + 1}`}
                              downloadStatus={true}
                              id={file.id}
                              name={file.name}
                              size={file.size}
                              filesUpload={filesUpload}
                              setFilesUpload={setFilesUpload}
                              i={i}
                              deleteHandler={handlerLicense}
                            />
                          );
                        })}
                      </div>
                    </DragAndDrop>

                    {/* <FormHelperText
                      sx={{
                        color: colors.red[700],
                      }}
                    >
                      {formik.errors.licenseFiles &&
                        formik.touched.licenseFiles &&
                        formik.errors.licenseFiles}
                    </FormHelperText> */}

                    <Button
                      color="primary"
                      fullWidth
                      size="medium"
                      variant="contained"
                      onClick={() => {
                        document.querySelector('#licenseFiles').click();
                      }}
                    >
                      Select files
                    </Button>
                  </Grid>
                  {medicalCardFileId ? (
                    <>
                      <Grid item xs={12} md={12} sm={12}>
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            style={{ width: 225 }}
                            className="mt-1"
                            type="button"
                            fullWidth
                            size="small"
                            variant="contained"
                            // href={`${process.env.REACT_APP_BASE_URL}/file/${this.state.medicalCardFileId}`}
                            onClick={() => {
                              fetch(
                                `${process.env.REACT_APP_BASE_URL}/file/${medicalCardFileId}`,
                                {
                                  method: 'GET',
                                  headers: {
                                    Authorization:
                                      'Bearer ' +
                                      localStorage.getItem(
                                        localStorage.getItem(
                                          'current_account'
                                        ) + '_access_token'
                                      ),
                                  },
                                }
                              ).then((response) => {
                                response.blob().then((blob) => {
                                  const spl = response.headers
                                    .get('content-disposition')
                                    .split(';');

                                  const filename = spl[1]
                                    .split('=')[1]
                                    .replace('"', '');
                                  const fileName2 = filename.replace('"', '');

                                  // Creating new object of PDF file
                                  const fileURL =
                                    window.URL.createObjectURL(blob);
                                  // Setting various property values
                                  const alink = document.createElement('a');
                                  alink.href = fileURL;
                                  alink.target = '_blank';
                                  alink.download = fileName2;
                                  alink.click();
                                });
                              });
                            }}
                          >
                            Download Medical Cart
                          </Button>
                          <Button
                            className="btn-icon mt-1"
                            color="error"
                            type="button"
                            onClick={() => deleteMedicalCard()}
                          >
                            <Icon.Trash2 />
                          </Button>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormGroup>
                        <Typography align="left" color={colors.grey[700]}>
                          Medical Cart
                        </Typography>
                        <CustomInput
                          type="file"
                          id="medicalCart"
                          onInput={(e) => uploadMedicalCart(e.target.files[0])}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {socialSecurityFileId ? (
                    <>
                      <Grid item xs={12} md={12} sm={12}>
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            style={{ width: 225 }}
                            className="mt-1"
                            type="button"
                            fullWidth
                            size="small"
                            variant="contained"
                            // href={`${process.env.REACT_APP_BASE_URL}/file/${this.state.socialSecurityFileId}`}
                            onClick={() => {
                              fetch(
                                `${process.env.REACT_APP_BASE_URL}/file/${socialSecurityFileId}`,
                                {
                                  method: 'GET',
                                  headers: {
                                    Authorization:
                                      'Bearer ' +
                                      localStorage.getItem(
                                        localStorage.getItem(
                                          'current_account'
                                        ) + '_access_token'
                                      ),
                                  },
                                }
                              ).then((response) => {
                                response.blob().then((blob) => {
                                  const spl = response.headers
                                    .get('content-disposition')
                                    .split(';');

                                  const filename = spl[1]
                                    .split('=')[1]
                                    .replace('"', '');
                                  const fileName2 = filename.replace('"', '');

                                  // Creating new object of PDF file
                                  const fileURL =
                                    window.URL.createObjectURL(blob);
                                  // Setting various property values
                                  const alink = document.createElement('a');
                                  alink.href = fileURL;
                                  alink.target = '_blank';
                                  alink.download = fileName2;
                                  alink.click();
                                });
                              });
                            }}
                          >
                            Download SSN
                          </Button>
                          <Button
                            className="btn-icon mt-1"
                            color="error"
                            type="button"
                            onClick={() => deleteSSN()}
                          >
                            <Icon.Trash2 />
                          </Button>
                        </div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item xs={12} md={12} sm={12}>
                      <FormGroup>
                        <Typography align="left" color={colors.grey[700]}>
                          SSN
                        </Typography>
                        <CustomInput
                          type="file"
                          id="ssn"
                          onInput={(e) => uploadSSN(e.target.files[0])}
                        />
                      </FormGroup>
                    </Grid>
                  )}
                  {driverId !== null ? (
                    <Grid item xs={12} md={12} sm={12}>
                      <Button
                        size="medium"
                        variant="contained"
                        onClick={() => {
                          setShowMobileAppAccessModal(true);
                        }}
                      >
                        <Icon.Edit size={22} />
                        &nbsp; Mobile App Access
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              {driverId ? (
                <Grid item xs={12} md={4} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} sm={12} my={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="sm"
                        className="d-flex align-items-center"
                        type="button"
                        onClick={() => {
                          setAdditionId();
                          setOpenAdditionModal(true);
                          // toggleRepairFormNew();
                        }}
                      >
                        <Icon.Plus size={20} /> &nbsp; New addition
                      </Button>
                      <Paper
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) =>
                                  column.id !== 'id' ? (
                                    <StyledTableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        minWidth: column.minWidth,
                                      }}
                                    >
                                      {column.label}
                                    </StyledTableCell>
                                  ) : null
                                )}
                                <StyledTableCell key="actions" align="left">
                                  ACTIONS
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {additions
                                .slice(
                                  pageAddition * rowsPerPageAddition,
                                  pageAddition * rowsPerPageAddition +
                                    rowsPerPageAddition
                                )
                                .map((row, i) => {
                                  return (
                                    <StyledTableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={`${i + 1}`}
                                    >
                                      {columns.map((column) => {
                                        if (column.id !== 'id') {
                                          let value = row[column.id];
                                          if (column.id === 'number') {
                                            value = i + 1;
                                          }
                                          if (column.id === 'date') {
                                            value = (
                                              <Moment format="MM/DD/YY">
                                                {row[column.id]}
                                              </Moment>
                                            );
                                          }
                                          return (
                                            <StyledTableCell
                                              key={column.id}
                                              // align={
                                              //     column.align
                                              // }
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onDoubleClick={() => {
                                                setAdditionId(row.id);
                                                // toggleRepairFormEdit();
                                              }}
                                            >
                                              {column.format &&
                                              typeof value === 'number'
                                                ? '$ ' + column.format(value)
                                                : value}
                                            </StyledTableCell>
                                          );
                                        }
                                      })}
                                      <StyledTableCell
                                        key={`${i + 1}`}
                                        // align={
                                        //     column.align
                                        // }
                                        align="right"
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                          >
                                            <Grid item>
                                              <Button
                                                size="sm"
                                                outline
                                                color="primary"
                                                className="d-flex align-items-center"
                                                type="button"
                                                onClick={() => {
                                                  setAdditionId(row.id);
                                                  setOpenAdditionModal(true);
                                                  // toggleRepairFormEdit();
                                                }}
                                              >
                                                <Icon.Edit size={16} />
                                              </Button>
                                            </Grid>
                                            {row.username === username ? (
                                              <Grid item>
                                                <Button
                                                  size="sm"
                                                  color="danger"
                                                  className="d-flex align-items-center"
                                                  type="button"
                                                  onClick={() => {
                                                    // setDeleteRepairStatus(true);
                                                    // setDeleteRepairId(row.id);
                                                  }}
                                                >
                                                  <Icon.Trash size={16} />
                                                </Button>
                                              </Grid>
                                            ) : null}
                                          </Grid>
                                        </>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                          <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={additions.length}
                            rowsPerPage={rowsPerPageAddition}
                            page={pageAddition}
                            onPageChange={handleChangePageAddition}
                            onRowsPerPageChange={
                              handleChangeRowsPerPageAddition
                            }
                          />
                        </TableContainer>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} md={12} sm={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="sm"
                        className="d-flex align-items-center"
                        type="button"
                        onClick={() => {
                          setDeductionId();
                          setOpenDeductionModal(true);
                        }}
                      >
                        <Icon.Plus size={20} /> &nbsp; New deduction
                      </Button>
                      <Paper
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                        }}
                      >
                        <TableContainer sx={{ maxHeight: 440 }}>
                          <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {columns.map((column) =>
                                  column.id !== 'id' ? (
                                    <StyledTableCell
                                      key={column.id}
                                      align={column.align}
                                      style={{
                                        minWidth: column.minWidth,
                                      }}
                                    >
                                      {column.label}
                                    </StyledTableCell>
                                  ) : null
                                )}
                                <StyledTableCell key="actions" align="left">
                                  ACTIONS
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {deductions
                                .slice(
                                  pageDeduction * rowsPerPageDeduction,
                                  pageDeduction * rowsPerPageDeduction +
                                    rowsPerPageDeduction
                                )
                                .map((row, i) => {
                                  return (
                                    <StyledTableRow
                                      hover
                                      role="checkbox"
                                      tabIndex={-1}
                                      key={`${i + 1}`}
                                    >
                                      {columns.map((column) => {
                                        if (column.id !== 'id') {
                                          let value = row[column.id];
                                          if (column.id === 'number') {
                                            value = i + 1;
                                          }
                                          if (column.id === 'date') {
                                            value = (
                                              <Moment format="MM/DD/YY">
                                                {row[column.id]}
                                              </Moment>
                                            );
                                          }
                                          return (
                                            <StyledTableCell
                                              key={column.id}
                                              // align={
                                              //     column.align
                                              // }
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                              onDoubleClick={() => {
                                                setDeductionId(row.id);
                                                setOpenDeductionModal(true);
                                                // toggleRepairFormEdit();
                                              }}
                                            >
                                              {column.format &&
                                              typeof value === 'number'
                                                ? '$ ' + column.format(value)
                                                : value}
                                            </StyledTableCell>
                                          );
                                        }
                                      })}
                                      <StyledTableCell
                                        key={`${i + 1}`}
                                        // align={
                                        //     column.align
                                        // }
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                      >
                                        <>
                                          <Grid
                                            container
                                            wrap="nowrap"
                                            spacing={2}
                                          >
                                            <Grid item>
                                              <Button
                                                size="sm"
                                                outline
                                                color="primary"
                                                className="d-flex align-items-center"
                                                type="button"
                                                onClick={() => {
                                                  setDeductionId(row.id);
                                                  setOpenDeductionModal(
                                                    !openDeductionModal
                                                  );
                                                  // toggleRepairFormEdit();
                                                }}
                                              >
                                                <Icon.Edit size={16} />
                                              </Button>
                                            </Grid>
                                            {row.username === username ? (
                                              <Grid item>
                                                <Button
                                                  size="sm"
                                                  color="danger"
                                                  className="d-flex align-items-center"
                                                  type="button"
                                                  onClick={() => {
                                                    // setDeleteRepairStatus(true);
                                                    // setDeleteRepairId(row.id);
                                                  }}
                                                >
                                                  <Icon.Trash size={16} />
                                                </Button>
                                              </Grid>
                                            ) : null}
                                          </Grid>
                                        </>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={additions.length}
                          rowsPerPage={rowsPerPageDeduction}
                          page={pageDeduction}
                          onPageChange={handleChangePageDeduction}
                          onRowsPerPageChange={handleChangeRowsPerPageDeduction}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 2,
                py: 2,
              }}
            >
              <Button
                sx={{
                  backgroundColor: '#CFE0F0',
                  color: '#161616',
                  // textTransform: 'none',
                }}
                variant="contained"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  // backgroundColor: '#2A455F',
                  color: 'white',
                  // textTransform: 'none',
                }}
                name="add"
                variant="contained"
                type="submit"
                color="primary"
                onClick={() => console.log()}
                startIcon={
                  formik.isSubmitting ? (
                    <CircularProgress size="0.9rem" />
                  ) : (
                    <Icon.Check size={22} />
                  )
                }
              >
                {renderButton(null, formik.isSubmitting)}
              </Button>
            </Box>
          </form>
        </CardBody>
      </Card>
    </>
  );
}
