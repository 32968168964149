import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Paper,
} from '@mui/material';
import Draggable from 'react-draggable';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import { Resizable } from 're-resizable';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, backgroundColor, ...other } = props;

  return (
    <DialogTitle
      aria-labelledby="draggable-dialog-title"
      sx={{ m: 0, p: 2, backgroundColor: '#4d4d4d', color: 'white' }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

// function PaperComponent(props) {
//   const onStart = () => {};

//   const onStop = () => {};
//   const dragHandlers = { onStart: { onStart }, onStop: { onStop } };
//   return (
//     <Draggable handle=".modal-title">
//       <ModalDialog {...props} />
//     </Draggable>
//   );
// }

export default ({
  title,
  modal = false,
  handleModal,
  maxWidth,
  body,
  backgroundColor = '#4d4d4d',
  dialogActions,
}) => {
  const [fullWidth, setFullWidth] = useState(true);
  const [activeDrags, setActiveDrags] = useState(0);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  return (
    // <Modal
    //   backdrop={false}
    //   show={modal}
    //   onHide={() => {
    //     handleModal();
    //   }}
    //   dialogAs={PaperComponent}
    //   scrollable={true}
    //   keyboard={false}
    //   centered
    // >
    //   <Resizable
    //     className="modal-resizable"
    //     defaultSize={{ width: 'auto', height: 'auto' }}
    //   >
    //     <Modal.Header>
    //       <Modal.Title>
    //         <BootstrapDialogTitle
    //           id="customized-dialog-title"
    //           onClose={() => handleModal(null)}
    //           backgroundColor={backgroundColor}
    //         >
    //           {title}
    //         </BootstrapDialogTitle>
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>{body}</Modal.Body>
    //     <Modal.Footer>
    //       <Button
    //         color="primary"
    //         variant="contained"
    //         autoFocus
    //         onClick={() => {
    //           handleModal(null);
    //         }}
    //       >
    //         Cancel
    //       </Button>
    //       {dialogActions}
    //     </Modal.Footer>
    //   </Resizable>
    // </Modal>
    <BootstrapDialog
      hideBackdrop
      disableEnforceFocus
      fullWidth={fullWidth}
      open={modal}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') {
          return;
        }
        handleModal();
      }}
      maxWidth={maxWidth}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <BootstrapDialogTitle
        id="draggable-dialog-title"
        onClose={() => handleModal(null)}
        backgroundColor={backgroundColor}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent className="no-cursor" dividers>
        {body}
      </DialogContent>
      {dialogActions}
    </BootstrapDialog>
  );
};
